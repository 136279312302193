import { MDXProvider } from "@mdx-js/react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import Ankle from '../components/Ankle'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import Footer from '../components/Footer'
import LinkButton from '../components/LinkButton'
import SectionHeader from '../components/SectionHeader'
import Slide from '../components/Slide'
import { shuffle } from '../lib/utils'
import './home.scss'



SwiperCore.use([Navigation]);
// Swiper.use([Navigation]);

const homePageCarouselSlides = () => {
  const carouselSlides = [
    { 
      linkTo: "/explore-a-clinic", 
      image: "/assets/home-slide-1.jpg", 
      title: "The clinic experience", 
      teaser: "We hope to prepare you for all parts of a clinic visit, so you know what to expect before, during, and after your appointment."
    },
    { 
      linkTo: "/preparing-for-your-visit", 
      image: "/assets/home-slide-2.jpg", 
      title: "Preparing for my healthcare visit", 
      teaser: "Preparing for a healthcare visit can be daunting, but it doesn’t have to be!"
    },
    { 
      linkTo: "/getting-to-know-your-body/", 
      image: "/assets/home-slide-3.jpg", 
      title: "Reproductive health and biology", 
      teaser: "Getting to know your body – how it works and what it needs – can be an exciting and lifelong process."},
  ]
  return (
    carouselSlides.map((slide, idx) => {
      const style = {
        backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 66%, rgba(0,0,0,0.9) 100%), url(${slide.image})`,
      };
      return (
        <SwiperSlide key={idx}>
          <div to={slide.linkTo} className="color-block" style={style}>
            <div className="transparent-box">
              <p className="title">{slide.title}</p>
              <p className="teaser">{slide.teaser}</p>
              <LinkButton
                to={slide.linkTo} label={'LEARN MORE'}>
              </LinkButton>
            </div>
          </div>

        </SwiperSlide>
      )
    })
  )

}

const ALWAYS_SEEN_TOPICS = [
  <Card
    link="/transitioning-to-adult-care/readiness-assessment"
    parentTitle="Taking charge of my healthcare"
    cardTitle="My readiness for healthcare on my own"
    image="/assets/topic-transitioning-to-adult-care.jpg"
  >
  How prepared do you feel to take charge of your health?
  </Card>,
  <Card
    link="/transitioning-to-adult-care/health-care-rights"
    parentTitle="Taking charge of my healthcare"
    cardTitle="My Healthcare Rights"
    image="/assets/6-topic-health-care-rights-coral.jpg"
  >
    Knowing your healthcare rights can help you to get the services you need and make the most of your healthcare visit.
  </Card>,
  <Card
    link="/sex-and-relationships/sexuality-and-gender-101"
    parentTitle="Sex and relationships"
    cardTitle="Sexuality 101"
    image="/assets/topic-sexuality-101-a.jpg"
  >
    Sexuality is about sex, but it’s also about much more.
  </Card>
]

const MORE_TOPICS = [
  <Card
    link="/preparing-for-your-visit/where-could-you-go/"
    parentTitle="Preparing for my healthcare visit"
    cardTitle="Finding sexual and reproductive health services"
    image="/assets/topic-where-could-go.jpg"
  >
    Knowing where to start looking for sexual and reproductive health services can be daunting - but it doesn't have to be!
  </Card>,
  <Card
    link="/preparing-for-your-visit/how-to-prepare-for-your-visit/"
    parentTitle="Preparing for my healthcare visit"
    cardTitle="Planning for my first clinic visit"
    image="/assets/7-topic-planning.png"
  >
    Great! You've made your appointment! Now what?
  </Card>,
  <Card
    link="/preparing-for-your-visit/how-to-pay-for-your-appointment/"
    parentTitle="Preparing for your visit"
    cardTitle="How to pay for my appointment"
    image="/assets/section-how-pay-appointment.jpg"
  >
    Explore different options for how you can pay for your healthcare visits
  </Card>,
  <Card
    link="/getting-to-know-your-body/reproductive-systems/"
    parentTitle="Reproductive health and biology"
    cardTitle="Reproductive systems"
    image="/assets/topic-reproductive-systems.jpg"
  >
    They allow people to make babies and have sexual pleasure. Meet the body's most amazing AND complicated system!
  </Card>,
  <Card
    link="/getting-to-know-your-body/menstruation"
    parentTitle="Reproductive health and biology"
    cardTitle="Menstruation"
    image="/assets/topic-menstruation.jpg"
  >
    For people with a uterus, menstruation (aka "getting a period"), is a normal part of a monthly cycle. What is it and what's happening in the body?
  </Card>,
  <Card
    link="/sex-and-relationships/sexual-health"
    parentTitle="HEALTHY LIFESTYLES"
    cardTitle="Sexual Health and wellness"
    image="/assets/topic-sexual-health-a.jpg"
  >
    A key part of physical and emotional health.
  </Card>,
  <Card link="/sex-and-relationships/sexuality-and-gender-101/sexual-and-gender-identity"
    parentTitle="Sex and relationships"
    cardTitle="Sexual & gender identity"
    image="/assets/rainbow_small.svg"
  >
    Our sexual selves – how we express our identities, and who are attracted to – can be a big part of who we are.
  </Card>,
  <Card
    link="/sex-and-relationships/healthy-relationships/healthy-communication"
    parentTitle="Healthy relationships"
    cardTitle="Healthy Communication"
    image="/assets/topic-healthy-communication.jpg"
  >
    Communication is key! How we can thrive in our relationships by encouraging open and honest communication?
  </Card>,
  <Card
    link="/sex-and-relationships/healthy-relationships/lets-talk-consent"
    parentTitle="Healthy relationships"
    cardTitle="Let's talk consent"
    image="/assets/3a-couple-hands-crop.jpg"
  >
    It’s enthusiastic, free of persuasion and fear, ongoing, and can be revoked at any time.
  </Card>,
  <Card
    link="/birth-control/"
    parentTitle=""
    cardTitle="Birth control"
    image="/assets/section-birth-control.jpg"
  >
    Explore your birth control options below and learn which method might be the best fit for you.
  </Card>,
  <Card
    link="/healthy-lifestyles/mental-health"
    parentTitle="Healthy lifestyles"
    cardTitle="Mental Health"
    image="/assets/topic-mental-health-c.jpg"
  >
    What are some common mental health challenges, what is mental health stigma, and what can support look like?
  </Card>,
  <Card
    link="/healthy-lifestyles/nutrition-and-exercise"
    parentTitle="Healthy lifestyles"
    cardTitle="Nutrition & Exercise"
    image="/assets/topic-exercise-nutrition-a.jpg"
  >
    How can we keep our bodies nourished and create habits that meet their unique needs?
  </Card>
  ];


export default function Home({data}) {

  const [showAllTopics, setShowAllTopics] = useState(false);
  const [moreTopics, setMoreTopics] = useState(MORE_TOPICS)

  const initialMobileTopics = ALWAYS_SEEN_TOPICS;
  const allMobileTopics = ALWAYS_SEEN_TOPICS.concat(moreTopics.slice(0, 6));
  const initialDesktopTopics = ALWAYS_SEEN_TOPICS.concat(moreTopics.slice(0, 3))
  const allDesktopTopics = ALWAYS_SEEN_TOPICS.concat(moreTopics.slice(0, 6));

  const shortcodes = { CardContainer, Card, Carousel, Slide, FactBox, LinkButton }

  useEffect(() => {
    const tmpTopics = [...moreTopics]
    shuffle(tmpTopics)
    setMoreTopics(tmpTopics)
  }, []);

  return (
    <div className="Home">
      <Helmet>
        <title>Wink</title>
        <meta name="description" content="Information and resources teens like you need about your health, and supports you in accessing healthcare services when you are ready." />
      </Helmet>
      <SectionHeader>
      </SectionHeader>
        <div className="top">
          <Swiper
            loop={true}
            centeredSlides={true}
            navigation={{
              "clickable": true}}
            pagination={{
              "clickable": true}}
          >
            {homePageCarouselSlides()}
          </Swiper>
        </div>
        <div className="content">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
          </MDXProvider>
        </div>
        <div className="topic-section">
          <h2>POPULAR TOPICS</h2>
          <div className="mobile topics">
            {
              showAllTopics ?  allMobileTopics : initialMobileTopics
            }
          </div>
          <div className="desktop topics">
            {
              showAllTopics ?  allDesktopTopics : initialDesktopTopics
            }
          </div>
          {!showAllTopics &&
            <button onClick={() => setShowAllTopics(true)}>LOAD MORE TOPICS</button>
          }
        </div>
      <Ankle>
      </Ankle>
      <Footer>
      </Footer>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    mdx(slug: {eq: ""}) {
      body
      frontmatter {
        modal
      }
    }
  }
`